<template>
  <div class="item">
    <TitleHeader msg="修改支付密码"></TitleHeader>
    <el-row class="Repass" v-if='!MobileFlag'>
      <p>请联系客服，认证手机号</p>
    </el-row>
    <el-row class="Repass" v-if='MobileFlag'>
      <el-col class="wraper">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
        >
          <span class="wraperTit" ></span><span></span>
          <el-form-item label="手机号：">
            <el-col :span="12">
              <span>{{phone}}</span>
            </el-col>
          </el-form-item>
          <el-form-item label="验证码：" prop="code">
              <el-input
                class="vscodeInps"
                v-model="ruleForm.code"
                ref="code"
                placeholder="输入验证码"
              ></el-input>
            <el-button
              ref="child"
              class="vscodeBtn"
              :class="{ disabled: !this.canClick }"
              @click="countDown"
              >{{ content }}</el-button
            >
          </el-form-item>
          <el-form-item class="newPass" label="新支付密码：" prop="newPassword">
            <el-input
              placeholder="请输入新支付密码"
              v-model="ruleForm.newPassword"
               class="vscodeInp"
              type="password"
               onkeyup="value=value.replace(/[^\d]/g,'')"
              autocomplete="off"
               :maxlength="6"
            >
            </el-input>
          </el-form-item>
          <el-form-item  class="passMsg">请设置您的新支付密码（密码由6位数字组成)</el-form-item>
          <el-form-item label="再次确认：" prop="checkPassword">
            <el-input
              placeholder="请再次输入新支付密码"
              v-model="ruleForm.checkPassword"
               class="vscodeInp"
              type="password"
                onkeyup="value=value.replace(/[^\d]/g,'')"
               autocomplete="off"
                :maxlength="6"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button     :class="!ruleForm.checkPassword || !ruleForm.newPassword || !ruleForm.code ? 'SureBtn disabled' : 'SureBtn'"   @click="submitForm('ruleForm')"
              >确定修改</el-button
            >
             <el-button class="resetBtn" @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.item {
  .Repass {
    padding: 30px;
    .num {
      font-weight: 600;
      color: #f04134;
      padding-right: 30px;
    }
    .wraper {
      display: flex;
      justify-content: left;
      align-items: center;
      .newPass{
        margin-bottom: 0px;
      }
      .passMsg{
        font-size: 12px;
        color: #999;
      }
      .el-input {
        position: relative;
        font-size: 14px;
        display: inline-block;
        width:50%;
      }
      
      .vscodeInps {
        position: relative;
        font-size: 14px;
        display: inline-block;
        width:26%;
      }
      .vscodeInp {
        position: relative;
        font-size: 14px;
        display: inline-block;
        width:50%;
      }
      .vscodeBtn {
        position: relative;
        font-size: 14px;
        display: inline-block;
        width:30%;
        background: #fff;
        color: #597EF7;
        border: none;
        cursor: pointer;
      }
      .SureBtn {
        background: @theme;
        color: #fff;
        border: 1px solid @theme;
        width: 136px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        font-size: 15px;
        font-weight: bold;
        border-radius: 0;
        padding: 0;
      }
      .SureBtn.disabled {
          background-color: #ddd;
          border-color: #ddd;
          color: @theme;
          cursor: not-allowed; // 鼠标变化
        }
      .resetBtn{
        width:66px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        font-size: 15px;
        font-weight: bold;
        border-radius: 0;
        padding: 0;
      }
    }
     /deep/.el-form{
       width: 64%;
     }
    /deep/.el-form-item__error {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 10px;
      left: calc(50% + 10px);
    }
  }
}
</style>
<script>
import Cookies from 'js-cookie';
import {updatePasswordBySmsCode,sendValidateCode} from "api/member.js"
const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
  name: "Repass",
  data() {
    //表单验证
    let validatecode = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机验证码不能为空'));
        }
        setTimeout(() => {
          if (!Number.isInteger(Number(value))) {
            callback(new Error('请输入数字值'));
          } else {
            if (value.length!=6) {
              callback(new Error('必须为六位数字'));
            } else {
              callback();
            }
          }
        }, 1000);
    };
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新支付密码"));
      } else {
        if (this.ruleForm.checkPassword !== "") {
          this.$refs.ruleForm.validateField("checkPassword");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新支付密码"));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error("两次输入支付密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      MobileFlag:'',
       // 点击倒计时
      content: "发送验证码", // 按钮里显示的内容
      totalTime: 60, //记录具体倒计时时间
      canClick: true, //添加canClick
      phone: '',
      ruleForm: {
        msg: "获取验证码",
        code: "",
        newPassword: "",
        checkPassword: "",
      },
      rules: {
        code:[{ validator: validatecode, trigger: "blur" }],
        newPassword: [{ validator: validatePass, trigger: "blur" }],
        checkPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    // 倒计时
    countDown() {
      if (!this.canClick) return; //改动的是这两行代码
      this.canClick = false;
      this.content = this.totalTime + "s后重新发送";
      //发送验证码
      sendValidateCode().then((data)=>{
        const h = this.$createElement;
        this.$notify({
          title: '成功',
          message:h('i', { style: 'color: teal'},data.data.msg),
          type: 'success'
        });
      })

      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重新发送验证码";
          this.totalTime = 60;
          this.canClick = true; //这里重新开启
        }
      }, 1000);
    },
    //   点击提交 验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let  Updatedata={
            smsCode:this.ruleForm.code,
            newPassword: this.$md5(this.ruleForm.newPassword)
          }
          //根据验证码修改支付密码
          updatePasswordBySmsCode(Updatedata).then((data)=>{
            if(data.data.msg=="验证码错误，请重新输入"){
               this.$message.error(data.data.msg);
               this.ruleForm.code='';
               this.$nextTick(() => {
                this.$refs.code.focus()
              },100)
               return
            }else{
              this.$message({
                message:data.data.msg,
                type: 'success'
              });
            }
          })

        } else {
          return false;
        }
      });
    },

    //重置
     resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    checkMobile(s){
      console.log('s: ', s);
        let length = s.length;
        if(length == 11 && /^(((13[0-9]{1})|(16[0-9]{1})|(15[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[0-9]{1})|)+\d{8})$/.test(s) ) 
        { 
            return true; 
        }else{ 
            return false; 
        } 
    }
  },
  created() {
    console.log('this.MobileFlag: ', Cookies.get('memberName'));
    this.MobileFlag=this.checkMobile(Cookies.get('memberName'))
    
    if(this.checkMobile(Cookies.get('memberName'))){
        this.phone=Number(Cookies.get('memberName'))
    }
  },
  components: {
    TitleHeader,
  },
};
</script>
